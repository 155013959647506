/**
 * Localization strings for app resources
 *
 * @module
 */

import { createDictionary } from './utils';

// Refer to "Guidelines for Programmers" in ./README.md before editing this file

export const resourcesText = createDictionary({
  appResources: {
    'en-us': 'App Resources',
    'ru-ru': 'Ресурсы приложения',
    'es-es': 'Recursos de la aplicación',
    'fr-fr': "Ressources de l'application",
    'uk-ua': 'Ресурси програми',
  },
  formDefinition: {
    'en-us': 'Form Definition',
    'ru-ru': 'Схема формы',
    'es-es': 'Definición de formulario',
    'fr-fr': 'Définition du formulaire',
    'uk-ua': 'Визначення форми',
  },
  formDefinitions: {
    'en-us': 'Form Definitions',
    'ru-ru': 'Определения форм',
    'es-es': 'Definiciones de formulario',
    'fr-fr': 'Définitions de formulaire',
    'uk-ua': 'Визначення форм',
  },
  loadFile: {
    'en-us': 'Load File',
    'ru-ru': 'Загрузить файл',
    'es-es': 'Cargar archivo',
    'fr-fr': 'Fichier de chargement',
    'uk-ua': 'Завантажити файл',
  },
  globalResources: {
    'en-us': 'Global Resources',
    'ru-ru': 'Глобальные ресурсы',
    'es-es': 'Recursos globales',
    'fr-fr': 'Ressources globales',
    'uk-ua': 'Глобальні ресурси',
  },
  disciplineResources: {
    'en-us': 'Discipline Resources',
    'ru-ru': 'Ресурсы дисциплины',
    'es-es': 'Recursos de disciplina',
    'fr-fr': 'Ressources de discipline',
    'uk-ua': 'Дисциплінарні ресурси',
  },
  type: {
    'en-us': 'Type',
    'ru-ru': 'Тип',
    'es-es': 'Tipo',
    'fr-fr': 'Type',
    'uk-ua': 'Тип',
  },
  userTypes: {
    'en-us': 'User Types',
    'ru-ru': 'Типы пользователей',
    'es-es': 'Tipos de usuarios',
    'fr-fr': "Types d'utilisateur",
    'uk-ua': 'Типи користувачів',
  },
  resources: {
    'en-us': 'Resources',
    'ru-ru': 'Ресурсы',
    'es-es': 'Recursos',
    'fr-fr': 'Ressources',
    'uk-ua': 'Ресурси',
  },
  subCategories: {
    'en-us': 'Sub-categories',
    'ru-ru': 'Подкатегории',
    'es-es': 'Subcategorías',
    'fr-fr': 'Sous-catégories',
    'uk-ua': 'Підкатегорії',
  },
  addResource: {
    'en-us': 'Add Resource',
    'ru-ru': 'Добавить ресурс',
    'es-es': 'Agregar recurso',
    'fr-fr': 'Ajouter une ressource',
    'uk-ua': 'Додати ресурс',
  },
  appResource: {
    'en-us': 'App Resource',
    'ru-ru': 'Ресурс приложения',
    'es-es': 'Recurso de la aplicación',
    'fr-fr': "Ressource d'application",
    'uk-ua': 'Ресурс програми',
  },
  rssExportFeed: {
    'en-us': 'RSS Export Feed',
    'ru-ru': 'RSS-канал экспорта',
    'es-es': 'Fuente de exportación RSS',
    'fr-fr': "Flux d'exportation RSS",
    'uk-ua': 'Канал експорту RSS',
  },
  expressSearchConfig: {
    'en-us': 'Express Search Config',
    'ru-ru': 'Конфигурация быстрого поиска',
    'es-es': 'Configuración de búsqueda rápida',
    'fr-fr': 'Configuration de la recherche express',
    'uk-ua': 'Конфігурація експрес-пошуку',
  },
  webLinks: {
    'en-us': 'Web Links',
    'ru-ru': 'Веб-ссылки',
    'es-es': 'Enlaces web',
    'fr-fr': 'Liens Web',
    'uk-ua': 'Веб-посилання',
  },
  uiFormatters: {
    'en-us': 'Field formatters',
    'ru-ru': 'Форматировщики полей',
    'es-es': 'formateadores de campo',
    'fr-fr': 'Formateurs de champ',
    'uk-ua': 'Форматери полів',
  },
  dataObjectFormatters: {
    'en-us': 'Record Formatters',
    'ru-ru': 'Форматировщики записей',
    'es-es': 'Formateadores de registros',
    'fr-fr': "Formateurs d'enregistrement",
    'uk-ua': 'Форматувальники записів',
  },
  searchDialogDefinitions: {
    'en-us': 'Search Dialog Definitions',
    'ru-ru': 'Макеты диалогового окна поиска',
    'es-es': 'Definiciones del diálogo de búsqueda',
    'fr-fr': 'Définitions de la boîte de dialogue de recherche',
    'uk-ua': 'Визначення діалогового вікна пошуку',
  },
  dataEntryTables: {
    'en-us': 'Data Entry Tables',
    'ru-ru': 'Таблицы ввода данных',
    'es-es': 'Tablas de entrada de datos',
    'fr-fr': 'Tableaux de saisie de données',
    'uk-ua': 'Таблиці введення даних',
  },
  interactionsTables: {
    'en-us': 'Interactions Tables',
    'ru-ru': 'Таблицы взаимодействий',
    'es-es': 'Tablas de interacciones',
    'fr-fr': "Tableaux d'interactions",
    'uk-ua': 'Таблиці взаємодій',
  },
  otherXmlResource: {
    'en-us': 'Other XML Resource',
    'ru-ru': 'Другой XML-ресурс',
    'es-es': 'Otro recurso XML',
    'fr-fr': 'Autre ressource XML',
    'uk-ua': 'Інший ресурс XML',
  },
  otherJsonResource: {
    'en-us': 'Other JSON Resource',
    'ru-ru': 'Другой JSON-ресурс',
    'es-es': 'Otro recurso JSON',
    'fr-fr': 'Autre ressource JSON',
    'uk-ua': 'Інший ресурс JSON',
  },
  otherPropertiesResource: {
    'en-us': 'Other Properties Resource',
    'ru-ru': 'Другой Properties-ресурс',
    'es-es': 'Recurso de otras propiedades',
    'fr-fr': 'Autre ressource de propriétés',
    'uk-ua': 'Ресурс інших властивостей',
  },
  otherAppResource: {
    'en-us': 'Other Resource',
    'ru-ru': 'Другой Ресурс',
    'es-es': 'Otro recurso',
    'fr-fr': 'Autre ressource',
    'uk-ua': 'Інший ресурс',
  },
  filters: {
    'en-us': 'Filters',
    'ru-ru': 'Фильтры',
    'es-es': 'filtros',
    'fr-fr': 'Filtres',
    'uk-ua': 'Фільтри',
  },
  custom: {
    'en-us': 'Custom',
    'ru-ru': 'Настроить',
    'es-es': 'Disfraz',
    'fr-fr': 'Personnalisé',
    'uk-ua': 'Custom',
  },
  leafletLayers: {
    'en-us': 'Leaflet Layers',
    'ru-ru': 'Слои Leaflet',
    'es-es': 'Capas de folletos',
    'fr-fr': 'Couches de dépliant',
    'uk-ua': 'Шари листівок',
  },
  textEditor: {
    'en-us': 'Text Editor',
    'ru-ru': 'Текстовый редактор',
    'es-es': 'Editor de texto',
    'fr-fr': 'Éditeur de texte',
    'uk-ua': 'Текстовий редактор',
  },
  visualEditor: {
    'en-us': 'Visual Editor',
    'ru-ru': 'Визуальный редактор',
    'es-es': 'editor visual',
    'fr-fr': 'Éditeur visuel',
    'uk-ua': 'Візуальний редактор',
  },
  selectResourceType: {
    'en-us': 'Select Resource Type',
    'ru-ru': 'Выберите тип ресурса',
    'es-es': 'Seleccionar tipo de recurso',
    'fr-fr': 'Sélectionnez le type de ressource',
    'uk-ua': 'Виберіть тип ресурсу',
  },
  globalPreferences: {
    'en-us': 'Global Preferences',
    'ru-ru': 'Глобальные настройки',
    'es-es': 'Preferencias globales',
    'fr-fr': 'Préférences globales',
    'uk-ua': 'Глобальні налаштування',
  },
  remotePreferences: {
    'en-us': 'Remote Preferences',
    'ru-ru': 'Удаленные настройки',
    'es-es': 'Preferencias remotas',
    'fr-fr': 'Préférences distantes',
    'uk-ua': 'Віддалені параметри',
  },
} as const);
